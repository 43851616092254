import styled from 'styled-components';
import { media } from 'styleguide/helpers/media';

interface Props {
	size?: 'normal' | 'wide';
}

const CalculationResultWrapper = styled.div<Props>`
	max-width: ${props => (props.size === 'wide' ? 780 : 580)}px;
	margin: ${({ theme }) => theme.grid.gutterInPx(2)} auto 0;
	text-align: center;
	padding: 0 ${({ theme }) => theme.grid.gutterInPx(2)};

	${media.tablet`
		padding: 0 0 ${({ theme }) => theme.grid.gutterInPx(4)};
		margin-top: -50px;
	`};
`;

export default CalculationResultWrapper;
