import * as React from 'react';
import styled from 'styled-components';
import Label from 'styleguide/components/Inputs/Label/Label';

interface MultiSelectFieldProps {
	label: string;
	info: string;
	children: React.ReactNode;
}

const InfoText = styled.div`
	${({ theme }) => theme.typography.heading};
	position: relative;
	font-size: 14px;
	color: ${({ theme }) => theme.colors.brandPrimary};
	margin-top: 0;
	margin-bottom: ${props => props.theme.grid.gutterInPx()};
`;

const MultiSelectContainer = styled.div`
	position: relative;
	margin-top: ${props => props.theme.grid.gutterInPx()};

	.form-field {
		margin: 4px 0 4px 0;
	}
`;

const MultiSelectItems = styled.div`
	border: 1px solid ${props => props.theme.colors.border};
	border-radius: 4px 4px 0 0;
	padding: ${props => props.theme.grid.gutterInPx()} ${props => props.theme.grid.gutterInPx(2)};
`;

export const MultiSelectField = (props: MultiSelectFieldProps) => {
	return (
		<MultiSelectContainer>
			<Label inline={false}>{props.label}</Label>
			{props.info && <InfoText>{props.info}</InfoText>}
			<MultiSelectItems>{props.children}</MultiSelectItems>
		</MultiSelectContainer>
	);
};

const SingleSelectContainer = styled.div`
	position: relative;
	margin-top: ${props => props.theme.grid.gutterInPx()};

	.form-field {
		margin: 4px 0 4px 0;
	}
`;

export const SingleSelectField = (props: MultiSelectFieldProps) => {
	return (
		<SingleSelectContainer>
			<Label inline={false}>{props.label}</Label>
			{props.info && <InfoText>{props.info}</InfoText>}
			{props.children}
		</SingleSelectContainer>
	);
};
