import * as React from 'react';
import ResultIcon from './ResultIcon';
import CalculationResultWrapper from './CalculationResultsWrapper';
import ResultTextWrapper from './ResultTextWrapper';
import ResultSummaryTextWrapper from './ResultSummaryTextWrapper';
import { withWindow } from 'styleguide/helpers';

export interface Props {
	isPositive: boolean;
	resultMessage?: string;
	summaryText?: React.ReactNode | string;
	size?: 'normal' | 'wide';
	icon?: React.ReactNode;
	scrollToResults?: boolean;
}

const SimpleCalculationResult: React.FC<Props> = ({
	icon,
	size,
	isPositive,
	resultMessage,
	summaryText,
	scrollToResults,
}) => {
	const wrapperRef = React.useRef<HTMLDivElement>(null);
	React.useEffect(() => {
		if (wrapperRef.current && scrollToResults === true) {
			withWindow(w => {
				wrapperRef.current?.scrollIntoView({ behavior: 'smooth' });
			});
		}
	}, []);
	return (
		<CalculationResultWrapper size={size} ref={wrapperRef}>
			<ResultIcon isPositive={isPositive} icon={icon} />
			{resultMessage && <ResultTextWrapper>{resultMessage}</ResultTextWrapper>}
			{summaryText && <ResultSummaryTextWrapper type="simple">{summaryText}</ResultSummaryTextWrapper>}
		</CalculationResultWrapper>
	);
};

export default SimpleCalculationResult;
