import { media } from 'styleguide/helpers/media';
import styled from 'styled-components';

interface Props {
	type?: 'simple';
}

const ResultSummaryTextWrapper = styled.div<Props>`
	margin-top: ${({ theme }) => theme.grid.gutterInPx(2)};
	font-size: 12px;
	font-family: ${({ theme }) => theme.fonts.secondary};
	font-weight: 400;
	line-height: 1.33;
	color: ${({ theme }) => theme.colors.bodyText};
	text-align: ${({ type }) => (type === 'simple' ? 'center' : 'left')};

	${media.tablet`
    font-size: 18px;
    margin-top: 38px;
	`};

	a {
		color: ${({ theme }) => theme.colors.linkText};
		text-decoration: none;

		&:after {
			content: ' ›';
		}
	}
`;

export default ResultSummaryTextWrapper;
