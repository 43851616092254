import * as React from 'react';
import CheckIcon from 'styleguide/components/Icons/NormalIcons/CheckIcon/CheckIcon';
import AlertIcon from 'styleguide/components/Icons/NormalIcons/AlertIcon/AlertIcon';
import styled from 'styled-components';
import { media } from 'styleguide/helpers/media';

const ResultIconBorder = styled.div`
	display: inline-block;
	background-color: ${({ theme }) => theme.colors.white};
	width: 72px;
	margin: 0 auto;

	${media.tablet`
		padding: ${({ theme }) => theme.grid.gutterInPx(2)};
  	border-radius: 50%;
		width: 100px;
  `};

	& > svg {
		width: 40px;
		height: 40px;

		${media.tablet`
      width: 64px;
      height: 64px;
    `};
	}
`;

interface Props {
	isPositive: boolean;
	icon?: React.ReactNode;
}

const ResultIcon = ({ isPositive, icon }: Props) => (
	<ResultIconBorder>
		{icon && icon}
		{!icon && (isPositive ? <CheckIcon /> : <AlertIcon />)}
	</ResultIconBorder>
);

export default ResultIcon;
