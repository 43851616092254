import * as React from 'react';
import styled, { DefaultTheme } from 'styled-components';
import { media } from 'styleguide/helpers/media';
import { getScrollbarWidth } from 'utils/document';
import { HeaderContext } from 'common/layouts/Empty/contexts';
import { WithLeadText } from '../../../types/leadText';

const whiteSpace = (theme: DefaultTheme) => theme.grid.gutter * 4 + 50;

const CalculatorAppletWrapper = styled.article<HeaderProps>`
	margin: 0px auto ${({ theme }) => theme.grid.gutterInPx(4)};

	${media.desktop<HeaderProps>`
		margin: ${props => (props.headerOffsetHeight > 0 ? 0 : props.headerHasSubNavigation ? 170 : 0)}px auto
		${({ theme }) => theme.grid.gutterInPx(4)};

	`}

	.form-field {
		margin-top: ${({ theme }) => theme.grid.gutterInPx(2)};
	}
`;

interface HeaderProps {
	headerOffsetHeight: number;
	headerHasSubNavigation?: boolean;
}

const Header = styled.header`
	width: 100%;
	padding-top: ${({ theme }) => whiteSpace(theme)}px;

	${media.desktop`
		width: calc(100vw - ${getScrollbarWidth()}px);
		margin: 0 calc(50% - 50vw + ${Math.floor(getScrollbarWidth() / 2)}px);
		background: ${props => props.theme.colors.contentSectionHiglighted};
	`};
`;

const HeaderContainer = styled.div`
	max-width: 980px;
	margin: 0 auto;
	text-align: center;
	display: flex;
	flex-direction: column;

	${media.tablet`
		padding: 0 ${({ theme }) => theme.grid.gutterInPx(4)} ${({ theme }) => `${theme.grid.gutter * 3 + 50}px`};
	`};

	${media.desktop`
		flex-direction: row;
		justify-content: space-between;
		text-align: left;
	`};

	${media.desktop1440`
		padding: 0 0 ${({ theme }) => whiteSpace(theme)}px;
	`};

	& img {
		${media.tablet`
			background: ${({ theme }) => theme.colors.white};
			border-radius: 50%;
		`};

		${media.desktop`
			width: 112px;
			height: 112px;
		`};
	}
`;

const TitleContainer = styled.div`
	${media.desktop`
		flex: 0 1 380px;
	`};
`;

const Title = styled.h1`
	${props => props.theme.typography.heading};
	text-align: center;
	font-size: 36px;
	line-height: 1;
	color: ${props => props.theme.colors.brandPrimary};
	margin: 0 auto ${props => props.theme.grid.gutterInPx(2)};

	${media.tablet`
		margin: ${props => props.theme.grid.gutterInPx(2)} 0 ${props => props.theme.grid.gutterInPx(3)};
	`};

	${media.desktop`
		text-align: left;
		font-size: 48px;
		margin: ${props => props.theme.grid.gutterInPx(3)} 0 ${props => props.theme.grid.gutterInPx(4)};
	`};
`;

const LeadText = styled.p`
	font-size: 14px;
	line-height: 20px;
	margin: 0;

	${media.tablet`
		font-size: 18px;
		line-height: 24px;
	`};

	${media.desktop`
		font-size: 22px;
		line-height: 28px;
	`};
`;

interface InputsAndSourcesWrapperProps {
	wide: boolean;
}

const InputsAndSourcesWrapper = styled.div<InputsAndSourcesWrapperProps>`
	margin-top: ${props => props.theme.grid.gutterInPx(3)};

	${media.desktop`
		align-self: flex-start;
		margin-top: 0;
		margin-left: ${props => props.theme.grid.gutterInPx(3)};		
		flex: 0 1 ${(props: InputsAndSourcesWrapperProps) => (props.wide ? '70%' : '50%')};
	`};
`;

const InputsWrapper = styled.div`
	${media.tablet`
		background: ${props => props.theme.colors.white};
		border: 1px solid ${props => props.theme.colors.border};
		border-radius: 4px;
		padding: ${props => props.theme.grid.gutterInPx(2)};
	`};

	${media.desktop`
		padding: ${props => props.theme.grid.gutterInPx(5)};
	`};

	.select-item-dropdown {
		margin-top: ${props => props.theme.grid.gutterInPx(2)};
	}
`;

const SourcesWrapper = styled.div`
	margin-top: ${props => props.theme.grid.gutterInPx(2)};

	${media.tablet`
		padding: ${props => props.theme.grid.gutterInPx()} ${props => props.theme.grid.gutterInPx(3)} 0;
	`};
`;

interface Props extends WithLeadText {
	icon: React.ReactNode;
	title: string;
	renderInputs: () => React.ReactNode;
	renderSources?: () => React.ReactNode;
	renderResult: () => React.ReactNode | null;
}

const CalculatorApplet = React.forwardRef<HTMLDivElement, Props>(
	({ icon, title, leadText, renderInputs, renderSources, renderResult }, ref) => {
		return (
			<HeaderContext.Consumer>
				{headerContext => (
					<CalculatorAppletWrapper
						headerOffsetHeight={headerContext.navigationTopOffset}
						headerHasSubNavigation={headerContext.hasSubNavigation}>
						<Header>
							<HeaderContainer>
								<TitleContainer>
									{icon}
									<Title>{title}</Title>
									<LeadText>{leadText}</LeadText>
								</TitleContainer>
								<InputsAndSourcesWrapper ref={ref as React.RefObject<HTMLDivElement>} wide={true}>
									<InputsWrapper>{renderInputs()}</InputsWrapper>
									{renderSources && <SourcesWrapper>{renderSources()}</SourcesWrapper>}
								</InputsAndSourcesWrapper>
							</HeaderContainer>
						</Header>
						{renderResult()}
					</CalculatorAppletWrapper>
				)}
			</HeaderContext.Consumer>
		);
	}
);

export default CalculatorApplet;
