import styled from 'styled-components';
import { media } from 'styleguide/helpers/media';

const ResultTextWrapper = styled.div`
	${({ theme }) => theme.typography.heading};
	margin-top: 12px;
	text-align: center;
	font-size: 20px;
	line-height: 1.2;
	color: ${({ theme }) => theme.colors.brandPrimary};

	${media.tablet`
    font-size: 32px;
    line-height: 1;
  `};
`;

export default ResultTextWrapper;
