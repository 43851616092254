import * as React from 'react';
import { generate } from 'shortid';
import styled from 'styled-components';
import ValidationError from '../ValidationError';

const RadioButtonInput = styled.input`
	position: absolute;
	left: 2px;
	top: 2px;

	&:focus {
		outline: none;
		box-shadow: none;

		& + label:before {
			border-color: ${({ theme }) => theme.colors.brandPrimary};
		}
	}

	&:checked + label:after {
		content: '';
	}

	&:disabled + label {
		color: ${({ theme }) => theme.colors.inputDisabledText};

		&:before {
			background: ${({ theme }) => theme.colors.inputDisabledBackground};
		}
		&:after {
			border-color: ${({ theme }) => theme.colors.border};
		}
	}
`;

const RadioButtonLabel = styled('label')<RadioButtonWrapperProps>`
	font-family: ${({ theme }) => theme.fonts.secondary};
	font-size: 18px;
	color: ${({ theme }) => theme.colors.bodyText};
	padding-left: 36px;
	position: relative;
	display: block;
	line-height: 24px;

	&:focus:before {
		border-color: ${({ theme }) => theme.colors.brandPrimary};
	}

	&:before {
		content: '';
		background: ${({ theme, isInvalid }) => (isInvalid ? theme.colors.actionErrorBackground : theme.colors.white)};
		border: 1px solid ${({ theme, isInvalid }) => (isInvalid ? theme.colors.actionErrorText : theme.colors.border)};
		position: absolute;
		top: 0;
		left: 0;
		width: 22px;
		height: 22px;
		border-radius: 30px;
	}

	&:after {
		content: none;
		display: inline-block;
		height: 14px;
		width: 14px;
		position: absolute;
		left: 5px;
		top: 5px;
		background-color: ${({ theme }) => theme.colors.brandPrimary};
		border-radius: 30px;
	}
`;

export interface RadioButtonWrapperProps {
	isInvalid?: boolean;
}

const RadioButtonWrapper = styled('div')<RadioButtonWrapperProps>`
	position: relative;

	/* Icon */
	div {
		background: ${({ theme, isInvalid }) => (isInvalid ? theme.colors.actionErrorBackground : theme.colors.white)};
		position: absolute;
		left: 1px;
		top: 1px;
		opacity: 0;
		width: 22px;
		height: 22px;
		border-radius: 4px;

		svg {
			width: 12px;
		}
	}
`;

export interface RadioButtonProps extends RadioButtonWrapperProps {
	name: string;
	validationError?: string;
}

type Props = RadioButtonProps & React.InputHTMLAttributes<HTMLInputElement>;

const RadioButton = React.forwardRef<HTMLInputElement, Props>(
	({ children, name, id, className, validationError, isInvalid, ...rest }, ref: React.RefObject<HTMLInputElement>) => {
		const radioButtonId = id || name || generate();

		return (
			<div className={`form-field${className ? ` ${className}` : ''}`}>
				<RadioButtonWrapper isInvalid={isInvalid}>
					<RadioButtonInput type="radio" name={name} id={radioButtonId} ref={ref} {...rest} />
					<RadioButtonLabel htmlFor={radioButtonId} isInvalid={isInvalid} className="checkbox-label">
						{children}
					</RadioButtonLabel>
				</RadioButtonWrapper>
				{validationError && <ValidationError htmlFor={radioButtonId}>{validationError}</ValidationError>}
			</div>
		);
	}
);

export default RadioButton;
