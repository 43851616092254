import { IntlShape } from 'react-intl';
import { QuestionState } from './PointsCalculatorApplet';

export interface PointsQuestion {
	id: string;
	multiSelect: boolean;
	items: number[];
	isHidden?: boolean;
}

export interface PointsCalculatorData {
	questions: PointsQuestion[];
}

export interface PointsSuccess {
	type: 'success';
	points: number;
	index: number;
	message: string;
	summary?: string;
}

export interface FieldError {
	error: string;
}

interface PointsError {
	type: 'error';
	error: string;
	fieldErrors: Array<FieldError | undefined>;
}

export type PointsResult = PointsSuccess | PointsError;

export abstract class PointsCalculatorHelper {
	protected intl: IntlShape;
	protected name: string;

	constructor(intl: IntlShape, name: string) {
		this.intl = intl;
		this.name = name;
	}

	public calculate(values: number[], questions: QuestionState[]): PointsResult {
		const points = values.reduce((sum, value) => sum + value, 0);
		const index = this.getIndex(points);
		const message = this.getMessage(index, points, questions);
		const summary = this.getSummary(index);
		return {
			type: 'success',
			points,
			index,
			message,
			summary,
		};
	}

	public abstract get data(): PointsCalculatorData;

	protected abstract getIndex(points: number): number;

	protected getMessage(index: number, points: number, questions: QuestionState[]): string {
		return `${this.intl.formatMessage({ id: `applet_common_points` }, { points })}: ${this.intl.formatMessage({
			id: `applet_${this.name}_result_index_${index}`,
		})}`;
	}

	protected abstract getSummary(index: number): string | undefined;
}
