import * as React from 'react';
import { FieldError, PointsCalculatorHelper, PointsResult } from './PointsCalculatorHelper';
import { AppletCommonProps } from '../types';
import { WrappedComponentProps, injectIntl, IntlShape } from 'react-intl';
import CalculatorApplet from './CalculatorApplet';
import { BarChartIcon } from 'styleguide/components/Icons';
import Checkbox from 'styleguide/components/Inputs/Checkbox/Checkbox';
import { SingleSelectField, MultiSelectField } from './PointsFields';
import Dropdown from 'styleguide/components/Inputs/Dropdown/Dropdown';
import SimpleCalculationResult from './SimpleCalculationResult';
import { flatMap } from 'utils/array';
import RadioButton from 'styleguide/components/Inputs/RadioButton';
import styled from 'styled-components';
import { media } from 'styleguide/helpers';

const InlineRadioButtonWrapper = styled.div`
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-content: flex-start;
	margin: ${props => props.theme.grid.gutter}px auto ${props => props.theme.grid.gutterInPx(4)}px;

	${media.desktop`
    justify-content: flex-start;
  `}
`;

const InlineRadioButtonItem = styled.div`
	flex: 0 0 auto;

	& + & {
		margin-left: 15px;
	}
`;

interface QuestionStateBase {
	id: string;
	items: number[];
	isVisible: boolean;
}

export interface SingleSelectQuestionState extends QuestionStateBase {
	multiSelect: false;
	selectedIndex: number | undefined;
}

export interface MultiSelectQuestionState extends QuestionStateBase {
	multiSelect: true;
	selectedIndexes: number[];
}

export type QuestionState = SingleSelectQuestionState | MultiSelectQuestionState;

export interface State {
	questions: QuestionState[];
}

export interface FieldResult {
	error?: FieldError;
	empty: boolean;
	value: number[] | undefined;
}

export enum SingleSelectQuestionType {
	Select,
	Radio,
}

interface Props {
	name: string;
	icon: React.ReactNode;
	singleSelectInputType?: SingleSelectQuestionType;
	helper: (intl: IntlShape) => PointsCalculatorHelper;
}

class PointsCalculatorApplet extends React.Component<Props & AppletCommonProps & WrappedComponentProps, State> {
	private helper: PointsCalculatorHelper;

	constructor(props: Props & AppletCommonProps & WrappedComponentProps) {
		super(props);

		this.helper = props.helper(props.intl);
		const data = this.helper.data;

		this.state = {
			questions: data.questions.map(({ isHidden, multiSelect, ...rest }) =>
				multiSelect
					? { ...rest, isVisible: !isHidden, multiSelect: true, selectedIndexes: [] }
					: { ...rest, isVisible: !isHidden, multiSelect: false, selectedIndex: undefined }
			) as QuestionState[],
		};
	}

	public render() {
		const { intl } = this.props;

		const title = intl.formatMessage({ id: `applet_${this.props.name}_title` });
		const leadText = intl.formatMessage({ id: `applet_${this.props.name}_lead_text` });

		return (
			<CalculatorApplet
				icon={this.props.icon}
				title={title}
				leadText={leadText}
				renderInputs={this.renderInputs}
				renderResult={this.renderResult}
			/>
		);
	}

	private renderInputs = () => {
		const questions = this.state.questions;
		return <>{questions.filter(q => q.isVisible).map(this.renderQuestion)}</>;
	};

	private renderQuestion = (question: QuestionState, questionIndex: number) => {
		if (question.multiSelect) {
			return this.renderMultiSelectQuestion(question, questionIndex);
		} else {
			return this.renderSingleSelectQuestion(question, questionIndex);
		}
	};

	private getInfo(questionId: string, defaultMessageId: string | undefined) {
		const { intl } = this.props;
		const noInfo = '\n';
		// id is returned if defaultMessage is empty string
		const info = intl.formatMessage({ id: `applet_${this.props.name}_${questionId}_info`, defaultMessage: noInfo });
		if (info === noInfo) {
			if (defaultMessageId) {
				return intl.formatMessage({ id: defaultMessageId });
			} else {
				return '';
			}
		} else {
			return info;
		}
	}

	private renderMultiSelectQuestion(question: MultiSelectQuestionState, questionIndex: number) {
		const { intl } = this.props;
		const info = this.getInfo(question.id, 'applet_points_select_many');
		return (
			<MultiSelectField
				key={questionIndex}
				label={intl.formatMessage({ id: `applet_${this.props.name}_${question.id}` })}
				info={info}>
				{question.items.map((item, itemIndex) => (
					<Checkbox
						key={itemIndex}
						name={`${this.props.id}_question_${questionIndex}_item_${itemIndex}`}
						checked={question.selectedIndexes.findIndex(num => num === itemIndex) >= 0}
						onChange={this.handleMultiSelectChange.bind(this, question, questionIndex, itemIndex)}>
						{intl.formatMessage({ id: `applet_${this.props.name}_${question.id}_${itemIndex}` })}
					</Checkbox>
				))}
			</MultiSelectField>
		);
	}

	private renderSingleSelectQuestion(question: SingleSelectQuestionState, questionIndex: number) {
		const { intl, singleSelectInputType } = this.props;
		const info = this.getInfo(question.id, '');
		const selectedValue = question.selectedIndex !== undefined ? question.selectedIndex.toString() : '';
		const inputType = singleSelectInputType ?? SingleSelectQuestionType.Select;
		return (
			<SingleSelectField
				key={questionIndex}
				label={intl.formatMessage({ id: `applet_${this.props.name}_${question.id}` })}
				info={info}>
				{inputType === SingleSelectQuestionType.Select ? (
					<Dropdown
						name={`${this.props.id}_question_${questionIndex}`}
						placeholder={intl.formatMessage({ id: 'global_choose' })}
						selectedValue={selectedValue}
						items={question.items.map((item, itemIndex) => ({
							name: intl.formatMessage({ id: `applet_${this.props.name}_${question.id}_${itemIndex}` }),
							value: itemIndex.toString(),
						}))}
						onChange={this.handleSingleSelectChange.bind(this, question, questionIndex)}
						validationError={undefined}
					/>
				) : (
					<InlineRadioButtonWrapper>
						{question.items.map((item, itemIndex) => (
							<InlineRadioButtonItem key={itemIndex}>
								<RadioButton
									id={`rbtn-${questionIndex}-${itemIndex}`}
									name={`${this.props.id}_question_${questionIndex}`}
									value={itemIndex}
									onClick={this.handleSingleSelectChange.bind(this, question, questionIndex, itemIndex)}>
									{intl.formatMessage({ id: `applet_${this.props.name}_${question.id}_${itemIndex}` })}
								</RadioButton>
							</InlineRadioButtonItem>
						))}
					</InlineRadioButtonWrapper>
				)}
			</SingleSelectField>
		);
	}

	private handleSingleSelectChange = (
		question: SingleSelectQuestionState,
		questionIndex: number,
		value: string,
		event?: React.FormEvent<HTMLInputElement>
	) => {
		const { questions } = this.state;
		const selectedIndex = parseInt(value, 10);
		question.selectedIndex = isNaN(selectedIndex) ? undefined : selectedIndex;

		this.setNextQuestionVisible(questionIndex, questions);
		this.setState({ questions });
	};

	private handleMultiSelectChange = (
		question: MultiSelectQuestionState,
		questionIndex: number,
		itemIndex: number,
		event: React.FormEvent<HTMLInputElement>
	) => {
		const { questions } = this.state;
		const checked = event.currentTarget.checked;
		if (checked) {
			question.selectedIndexes = [...question.selectedIndexes, itemIndex];
		} else {
			question.selectedIndexes = question.selectedIndexes.filter(index => index !== itemIndex);
		}

		this.setNextQuestionVisible(questionIndex, questions);
		this.setState({ questions });
	};

	private setNextQuestionVisible = (currentQuestionIndex: number, questions: QuestionState[]) => {
		const nextQuestionIndex = currentQuestionIndex + 1;
		const nextQuestion = questions.length >= nextQuestionIndex ? questions[nextQuestionIndex] : null;
		if (nextQuestion) {
			nextQuestion.isVisible = true;
		}
	};

	private renderResult = () => {
		const result = this.getResult();

		if (!result || result.type === 'error') {
			return null;
		}

		return (
			<SimpleCalculationResult
				icon={<BarChartIcon />}
				size="wide"
				isPositive={result.type === 'success'}
				resultMessage={result.message}
				summaryText={result.summary}
				scrollToResults={true}
			/>
		);
	};

	private getResult(): PointsResult | undefined {
		const questions = this.state.questions;
		const fields = questions.map(question => this.validateQuestion(question));
		const allEmpty = fields.filter(f => f.empty).length === fields.length;
		const someEmpty = fields.some(f => f.empty);

		if (allEmpty || someEmpty) {
			return undefined;
		}

		const values = flatMap(fields, field => field.value!);
		const result = this.helper.calculate(values, questions);
		return result;
	}

	private validateQuestion(question: QuestionState): FieldResult {
		const { intl } = this.props;

		if (question.multiSelect) {
			return { error: undefined, empty: false, value: question.selectedIndexes.map(index => question.items[index]) };
		}

		if (question.selectedIndex === undefined) {
			return {
				error: { error: intl.formatMessage({ id: `applet_${this.props.name}_validation_error_value` }) },
				empty: true,
				value: undefined,
			};
		} else {
			return { error: undefined, empty: false, value: [question.items[question.selectedIndex]] };
		}
	}
}
export default injectIntl(PointsCalculatorApplet);
